import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const ShoppersStop = () => {
  const headingbanner = {
    title: `Style Spot`,
    content: `An E-Commerce Platform`,
  };

  const data = {
    images: ["shoppers-stop.webp", "shoppers-stop-2.webp"],
    firstAlt: "Dashboard to check real-time performance and logistics performance",
    firstTitle: "Logistics Performance",
    secondAlt: "UI Screen of Service Provider Details, thier status, performance, etc.",
    secondTitle: "Service Provider Details",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Enterprise"],
      },
      {
        title: "What we did",
        text: [
          "UX Consultation",
          "UI UX Designing",
          "UI Development",
        ],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `Our main challenge was to simplify user flows and improve the intuitiveness of the data visualizations, allowing users to understand and analyze data quickly.`,
          `Additionally, we needed to develop a system enabling users to create custom policies for assessing performance, providing greater flexibility and adaptability for their specific needs.`,
          `We also had to focus on streamlining the workflows, organizing the information architecture, and implementing advanced data visualization techniques.`
        ],
      },
      {
        title: "Solution",
        content: [
          `Our main objective was to enhance operational efficiency in managing shipments by optimizing the design for improved visibility and performance management.`,
          `We aimed to simplify operations, ensuring that every role had clear and distinct actions to perform.`,
          `By implementing our solution, we enabled end-to-end operation visibility, allowing stakeholders to easily track the progress of shipments at every stage.`,
          `Additionally, our solution featured performance distress handling, offering a streamlined and effective process for addressing any issues or challenges that may arise during shipment handling.`,
        ],
      },
    ],
    image: ["shoppers-stop-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Dashboard",
    para: [
      `In order to provide an overview of the shipment, we created this user-friendly dashboard which can be customised as per the user's need. We also offered real-time updates to ensure a smooth flow of operations.`,
    ],
    content: {
      image: ["shoppers-stop-projectone.webp"],
      imageAlt: 'Dashboard to check real-time performance and logistics performance',
      imageTitle: 'Logistics Performance',
    },
  };

  const projectData2 = {
    title: `Service Providers`,
    para: [
      `We created this interface to offer detailed information on the service providers along with their individual shipment stats. This helps the user to track any inefficiencies in the shipment process.`,
    ],
    content: {
      image: ["shoppers-stop-projecttwo.webp"],
      imageAlt: 'UI Screen of Service Provider Details, thier status, performance, etc.',
      imageTitle: 'Service Provider Details',
    },
  };

  const projectData3 = {
    title: "Shipment",
    para: [
      `This interface shows the individual shipment details with their status. Users can also customise and download logistic reports to create and implement supply chain strategies for smooth business flow.`,
    ],
    content: {
      image: [
        "shoppers-stop-projectthree.webp",
        "shoppers-stop-projectthree-2.webp",
        "shoppers-stop-projectthree-3.webp",
        "shoppers-stop-projectthree-4.webp",
        "shoppers-stop-projectthree-5.webp",
        "shoppers-stop-projectthree-6.webp",
      ],
      firstAlt: 'UI to check shipment details and its status',
      firstTitle: 'Shipment Status',
      secondAlt: 'UI to check city wise shipment performance',
      secondTitle: 'Reports- Performance',
      thirdAlt: 'UI to check pending pickup status of orders',
      thirdTitle: 'Reports- Peding Pickup',
      fourthAlt: 'UI to check the service provider status',
      fourthTitle: 'Service Provider',
      fifthAlt: 'Dashboard to check real-time performance and logistics performance',
      fifthTitle: 'Logistics Performance',
      sixthAlt: 'UI Screen of Service Provider Details, thier status, performance, etc.',
      sixthTitle: 'Service Provider Details',
    },
  };

  const conclusionData = [
    {
      para: [
        `In conclusion, our solution provided a comprehensive and efficient solution for managing all aspects of fashion retail.`,
        `By simplifying user flows, improving data visualizations, and allowing for custom policies, we have created a user-friendly platform that enhances operational efficiency and performance management.`,
        `With end-to-end visibility and streamlined distress handling, our platform supports seamless shipment handling and ensures a smooth and successful fashion retail experience for our users.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={36} />
      </div>
    </Layout>
  );
};

export default ShoppersStop;

export const Head = () => (
  <Seo title="Style Spot - Octet Design Studio" description="We provided UX consultation, UI UX design and frontend development to e-commerce shipping platform-Shopper's stop." />
)